export {
  CNIC_LENGTH,
  CONTACT_NUMBER_LENGTH,
  DATE_FORMAT,
  DATE_FORMAT_ISO,
  DATE_TIME_FORMAT,
  DAY_IN_MINUTES,
  EMAIL_FORMAT,
  FEATURE_DISABLED_TEXT,
  FULL_CALENDAR_FORMAT,
  HOLIDAY_DATE_FORMAT,
  HOLIDAY_DAY_FORMAT,
  INVOICE_DATE_FORMAT,
  MAX_DOCUMENT_SIZE_LIMIT,
  MAX_IMAGE_SIZE_LIMIT,
  NOT_APPLICABLE_TEXT,
  NOTIFICATOINS_PAGE_SIZE,
  ONBOARDING_ID_PREFIX,
  REACT_CALENDAR_FORMAT,
  REDEEMABLE_SLOT_FORMAT,
  REPORT_DATE_FORMAT,
  SHORT_DATE_FORMAT,
  SICK_LEAVE_IMAGES_LIMIT,
  TWELVE_HOUR_TIME_FORMAT,
  WEB_VIEW
} from 'maple-storybook/constant'

export const ONLY_DAY_FORMAT = 'Do'
export const DAY_MONTH_FORMAT = 'DD MMMM'
export const PMS_DATE_FORMAT = 'DD-MM-YYYY'
export const TWO_DIGIT_FORMAT = 'MM-DD-YYYY'
export const MONTH_YEAR = 'MM-YYYY'

export const tabularAvatar = {
  width: '30px',
  height: '30px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%'
}

export const AMS_IFRAME_ID = 'ams-iframe'
export const AMS_IFRAME_ROUTE_PREFIX = '/ams-embed'
