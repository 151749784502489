import * as types from 'actions/actionTypes'

import initialState from './initialState'

export const allowance = (state = initialState.allowances, action) => {
  switch (action.type) {
    case types.FETCH_ALLOWANCE_SUCCESS:
      return {
        ...state,
        allowances: action.payload
      }

    default:
      return state
  }
}
