import * as types from 'actions/actionTypes'
import { totalItems } from 'helpers/applicationHelper'

import initialState from './initialState'

export const project = (state = initialState.projects, action) => {
  let projectMemberIndex,
    projectMembers,
    projectIndex,
    profileIndex,
    project,
    projects,
    userProjectsHistory,
    profiles,
    pseudoProfileIndex,
    designations,
    pseudoDesignationIndex,
    threadIndex,
    threads
  switch (action.type) {
    case types.CREATE_PROJECT_SUCCESS:
      return { ...state, projects: [...state.projects, action.payload], projectCreated: true }

    case types.CREATE_PROJECT_FAILURE:
      return state

    case types.FETCH_PROJECTS_SUCCESS:
      return { ...state, projects: action.payload, projectListLoader: false }

    case types.FETCH_PROJECTS_FAILURE:
      return { ...state, projectListLoader: false }

    case types.FETCH_PROJECT_SUCCESS:
      return { ...state, project: action.payload, projectListLoader: false }

    case types.FETCH_PROJECT_ELIGIBLE_MEMBERS_SUCCESS:
      return { ...state, projectEligibleUsers: action.payload }

    case types.UPDATE_PROJECT_MEMBERS_SUCCESS:
      return {
        ...state,
        projectEligibleUsers: action.payload
      }

    case types.FETCH_PROJECT_FAILURE:
      return { ...state, projectListLoader: false }

    case types.UPDATE_PROJECT_ELIGIBLE_MEMBERS_SUCCESS:
      return { ...state, projectEligibleUsers: action.payload }

    case types.UPDATE_PROJECT_SUCCESS:
      projectIndex = state.projects.findIndex((project) => project.id === action.payload.id)

      projects = [...state.projects]
      projects[projectIndex] = action.payload

      return { ...state, projects: [...projects] }

    case types.UPDATE_PROJECT_FAILURE:
      return state

    case types.DELETE_PROJECT_SUCCESS:
      if (action.payload.id) {
        projectIndex = state.projects.findIndex((project) => project.id === action.payload.id)
      }

      projects = [...state.projects]
      projects.splice(projectIndex, 1)

      return { ...state, projects: [...projects] }

    case types.DELETE_PROJECT_FAILURE:
      return state

    case types.UPDATE_PROJECT_STATUS_SUCCESS:
      projectIndex = state.projects.findIndex((project) => project.id === action.payload.project_id)

      projects = [...state.projects]
      projects[projectIndex].status.active_status = action.payload.status

      return { ...state, projects: [...projects] }

    case types.UPDATE_PROJECT_STATUS_FAILURE:
      return state

    case types.SET_PROJECT_CREATED:
      return { ...state, projectCreated: false }

    case types.SET_PROJECTS_TAB:
      return { ...state, tab: action.payload.tab }

    case types.FETCH_USER_PROJECTS_SUCCESS:
      return { ...state, userProject: action.payload }

    case types.FETCH_USER_PROJECTS_FAILURE:
      return state

    case types.LOADING_PROJECT_LIST:
      return { ...state, projectListLoader: true }

    case types.FETCH_USERS_PROJECTS_SUCCESS:
      return { ...state, usersProjects: action.payload }

    case types.FETCH_USERS_PROJECTS_FAILURE:
      return state

    case types.CREATE_PROJECT_MEMBER_SUCCESS:
      return { ...state, projectMembers: [...state.projectMembers, action.payload] }

    case types.CREATE_PROJECT_MEMBER_FAILURE:
      return state

    case types.FETCH_PROJECT_MEMBERS_SUCCESS:
      return { ...state, projectMembers: action.payload }

    case types.FETCH_PROJECT_MEMBERS_FAILURE:
      return state

    case types.CLEAR_USER_PROJECT_HISTORY:
      return { ...state, userProjectHistory: [] }

    case types.DELETE_PROJECT_MEMBER_SUCCESS:
      projectMemberIndex = state.projectMembers.findIndex((member) => member.id === action.payload.id)

      projectMembers = [...state.projectMembers]
      projectMembers.splice(projectMemberIndex, 1)

      return { ...state, projectMembers: [...projectMembers] }

    case types.DELETE_PROJECT_MEMBER_FAILURE:
      return state

    case types.UPDATE_PROJECT_MEMBER_SUCCESS:
      project = { ...state.project }

      if (action.payload.membership_type === 'project_lead') {
        projectMemberIndex = state.project.leads.findIndex((member) => member.member_id === action.payload.id)
        project.leads[projectMemberIndex].end_date = action.payload.end_date
      }

      if (action.payload.membership_type === 'project_manager') {
        projectMemberIndex = state.project.managers.findIndex((member) => member.member_id === action.payload.id)
        project.managers[projectMemberIndex].end_date = action.payload.end_date
      }

      if (action.payload.membership_type != 'project_manager' || action.payload.membership_type != 'project_lead') {
        projectMemberIndex = state.project.team.findIndex((member) => member.member_id === action.payload.id)
        project.team[projectMemberIndex].end_date = action.payload.end_date
      }

      return { ...state, project: project }

    case types.UPDATE_PROJECT_MEMBER_FAILURE:
      return state

    case types.FETCH_USERS_FOR_PROJECT_SUCCESS:
      return { ...state, projectUsers: action.payload }

    case types.FETCH_USERS_FOR_PROJECT_FAILURE:
      return state

    case types.CLEAR_PROJECT_MEMBERS:
      return { ...state, userProjects: [], userProject: [] }

    case types.FETCH_USER_PROJECTS_HISTORY_SUCCESS:
      return { ...state, userProjectsHistory: action.payload }

    case types.CREATE_USER_PROJECTS_HISTORY_SUCCESS:
      return { ...state, userProjectsHistory: [...state.userProjectsHistory, action.payload] }

    case types.CREATE_USER_PROJECTS_HISTORY_FAILURE:
      return state

    case types.DELETE_USER_PROJECTS_HISTORY_SUCCESS:
      projectMemberIndex = state.userProjectsHistory.findIndex((member) => member.id === action.payload.id)

      userProjectsHistory = [...state.userProjectsHistory]
      userProjectsHistory.splice(projectMemberIndex, 1)

      return { ...state, userProjectsHistory: [...userProjectsHistory] }

    case types.DELETE_USER_PROJECTS_HISTORY_FAILURE:
      return state

    case types.FETCH_USERS_FOR_PROJECT_MODULE_SUCCESS:
      return { ...state, projectModuleUsers: action.payload }

    case types.FETCH_USERS_FOR_PROJECT_MODULE_FAILURE:
      return state

    case types.FETCH_USER_PROJECTS_FOR_CLAIMS_SUCCESS:
      return { ...state, userProjectsForClaims: action.payload }

    case types.FETCH_USER_PROJECTS_FOR_CLAIMS_FAILURE:
      return state

    case types.CREATE_PSEUDO_PROFILE_SUCCESS:
      sessionStorage.setItem('totalItems', parseInt(totalItems(), 10) + 1)
      return { ...state, pseudoProfiles: [...state.pseudoProfiles, action.payload] }

    case types.CREATE_PSEUDO_PROFILE_FAILURE:
      return state

    case types.UPDATE_PSEUDO_PROFILE_SUCCESS:
      pseudoProfileIndex = state.pseudoProfiles.findIndex((profile) => profile.id === action.payload.id)

      profiles = [...state.pseudoProfiles]
      profiles[pseudoProfileIndex] = action.payload

      return { ...state, pseudoProfiles: [...profiles] }

    case types.UPDATE_PSEUDO_PROFILE_FAILURE:
      return state

    case types.UPDATE_THREAD_STATUS_SUCCESS:
      profileIndex = state.project.project_pseudo_profiles.findIndex(
        (profile) => profile.id === action.payload.project_pseudo_profile_id
      )
      project = { ...state.project }
      project.project_pseudo_profiles[profileIndex].status.active_status = action.payload.status
      project.project_pseudo_profiles[profileIndex].status.effective_date = action.payload.start_date

      return { ...state, project: project }

    case types.UPDATE_THREAD_STATUS_FAILURE:
      return state

    case types.FETCH_PSEUDO_PROFILES_SUCCESS:
      return { ...state, pseudoProfiles: action.payload, projectListLoader: false }

    case types.FETCH_PSEUDO_PROFILES_FAILURE:
      return { ...state, projectListLoader: false }

    case types.CREATE_PSEUDO_DESIGNATION_SUCCESS:
      sessionStorage.setItem('totalItems', parseInt(totalItems(), 10) + 1)

      return { ...state, pseudoDesignations: [...state.pseudoDesignations, action.payload] }

    case types.CREATE_PSEUDO_DESIGNATION_FAILURE:
      return state

    case types.UPDATE_PSEUDO_DESIGNATION_SUCCESS:
      pseudoDesignationIndex = state.pseudoDesignations.findIndex((designation) => designation.id === action.payload.id)

      designations = [...state.pseudoDesignations]
      designations[pseudoDesignationIndex] = action.payload

      return { ...state, pseudoDesignations: [...designations] }

    case types.UPDATE_PSEUDO_DESIGNATION_FAILURE:
      return state

    case types.FETCH_PSEUDO_DESIGNATIONS_SUCCESS:
      return { ...state, pseudoDesignations: action.payload, projectListLoader: false }

    case types.FETCH_PSEUDO_DESIGNATIONS_FAILURE:
      return { ...state, projectListLoader: false }

    case types.FETCH_CSV_DATA_SUCCESS:
      return { ...state, sampleCsvData: action.payload }

    case types.FETCH_CSV_DATA_FAILURE:
      return state

    case types.UPLOAD_INVOICE_CSV_SUCCESS:
      return { ...state, monthlyInvoice: [...action.payload] }

    case types.UPLOAD_INVOICE_CSV_FAILURE:
      return { ...state, csvErrors: action.payload.errors }

    case types.FETCH_MONTHLY_INVOICE_DATA_SUCCESS:
      return { ...state, monthlyInvoice: action.payload, projectListLoader: false }

    case types.FETCH_MONTHLY_INVOICE_DATA_FAILURE:
      return { ...state, projectListLoader: false }

    case types.FETCH_INVOICE_CSV_ATTRIBUTES_SUCCESS:
      return { ...state, csvAttributes: action.payload }

    case types.FETCH_INVOICE_CSV_ATTRIBUTES_FAILURE:
      return state

    case types.FETCH_PROJECT_CSV_ATTRIBUTES_SUCCESS:
      return { ...state, memberCsvAttributes: action.payload }

    case types.FETCH_PROJECT_CSV_ATTRIBUTES_FAILURE:
      return state

    case types.FETCH_PROJECT_CSV_DATA_SUCCESS:
      return { ...state, memberCsvData: action.payload }

    case types.FETCH_PROJECT_CSV_DATA_FAILURE:
      return state

    case types.FETCH_PROJECT_TIMELINE_SUCCESS:
      return { ...state, projectTimeline: action.payload }

    case types.FETCH_SELECT_PSEUDO_PROFILE_SUCCESS:
      return { ...state, selectTimelinePseudoProfiles: action.payload }

    case types.UPDATE_PROJECT_PSEUDO_PROFILE_SUCCESS:
      threadIndex = state.selectTimelinePseudoProfiles.findIndex(
        (thread) => thread.id === action.payload.project_pseudo_profile.id
      )
      threads = [...state.selectTimelinePseudoProfiles]
      threads[threadIndex] = action.payload.project_pseudo_profile
      profileIndex = state.project.project_pseudo_profiles.findIndex(
        (profile) => profile.id === action.payload.project_pseudo_profile.id
      )
      project = { ...state.project }
      project.project_pseudo_profiles[profileIndex] = action.payload.edit_project_pseudo_profile

      return {
        ...state,
        project: project,
        selectTimelinePseudoProfiles: [...threads]
      }

    case types.UPDATE_PROJECT_PSEUDO_PROFILE_FAILURE:
      return state

    case types.DESTROY_PROJECT_PSEUDO_PROFILE_STATUS_SUCCESS:
      threadIndex = state.selectTimelinePseudoProfiles.findIndex(
        (thread) => thread.id === action.payload.project_pseudo_profile.id
      )
      threads = [...state.selectTimelinePseudoProfiles]
      threads[threadIndex] = action.payload.project_pseudo_profile
      profileIndex = state.project.project_pseudo_profiles.findIndex(
        (profile) => profile.id === action.payload.project_pseudo_profile.id
      )
      project = { ...state.project }
      project.project_pseudo_profiles[profileIndex] = action.payload.edit_project_pseudo_profile

      return {
        ...state,
        project: project,
        selectTimelinePseudoProfiles: [...threads]
      }

    case types.DESTROY_PROJECT_PSEUDO_PROFILE_STATUS_FAILURE:
      return state

    case types.FETCH_SELECT_TIMELINE_PROJECTS_SUCCESS:
      return { ...state, selectTimelineProjects: action.payload }

    case types.FETCH_PROJECT_SERVICES_SUCCESS:
      return { ...state, projectServices: action.payload }

    default:
      return state
  }
}
