import { toastr } from 'react-redux-toastr'

import { enableFormSubmission } from 'actions/formSubmission'
import { errorsAlert } from 'helpers/applicationHelper'

import * as types from './actionTypes'
import { HIDE_MODAL } from './modal'

export const createProjectSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_PROJECT_SUCCESS,
    payload
  })
  toastr.success('Project Created Successfully!')
}

export const createProjectFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_PROJECT_FAILURE,
    payload
  })
  errorsAlert(payload, 'Failed to Create Project!')
}

export const fetchProjectsSuccess = (payload) => ({
  type: types.FETCH_PROJECTS_SUCCESS,
  payload
})

export const fetchProjectsFailure = (payload) => ({
  type: types.FETCH_PROJECTS_FAILURE,
  payload
})

export const fetchProjectSuccess = (payload) => ({
  type: types.FETCH_PROJECT_SUCCESS,
  payload
})
export const updateEligibleMembersSuccess = (payload) => ({
  type: types.UPDATE_PROJECT_MEMBERS_SUCCESS,
  payload
})

export const fetchProjectEligibleMembersSuccess = (payload) => ({
  type: types.FETCH_PROJECT_ELIGIBLE_MEMBERS_SUCCESS,
  payload
})

export const fetchProjectEligibleMembersFailure = (payload) => {
  errorsAlert(payload, 'Failed to fetch Project Eligble Members!')
}

export const fetchProjectFailure = (payload) => ({
  type: types.FETCH_PROJECT_FAILURE,
  payload
})

export const updateProjectSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_PROJECT_SUCCESS,
    payload
  })
  toastr.success('Project Updated Successfully!')
}

export const updateProjectFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_PROJECT_FAILURE,
    payload
  })
  dispatch(enableFormSubmission)
  errorsAlert(payload, 'Failed to update Project!')
}

export const deleteProjectSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.DELETE_PROJECT_SUCCESS,
    payload
  })

  toastr.success('Project Deleted Successfully!')
}

export const deleteProjectFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.DELETE_PROJECT_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to Delete Project!')
}

export const updateProjectStatusSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_PROJECT_STATUS_SUCCESS,
    payload
  })
  dispatch(HIDE_MODAL)
  toastr.success('Project Status Updated Successfully!')
}

export const updateProjectStatusFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_PROJECT_STATUS_FAILURE,
    payload
  })
  errorsAlert(payload, 'Failed to update Project Status!')
}

export const createPseudoProfileSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_PSEUDO_PROFILE_SUCCESS,
    payload
  })

  toastr.success('Pseudo Profile Created Successfully!')
}

export const createPseudoProfileFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_PSEUDO_PROFILE_FAILURE,
    payload
  })
  errorsAlert(payload, 'Failed to Create Pseudo Profile!')
}

export const updatePseudoProfileSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_PSEUDO_PROFILE_SUCCESS,
    payload
  })

  toastr.success('Pseudo Profile Updated Successfully!')
}

export const updatePseudoProfileFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_PSEUDO_PROFILE_FAILURE,
    payload
  })
  errorsAlert(payload, 'Failed to update Pseudo Profile!')
}

export const updateThreadStatusSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_THREAD_STATUS_SUCCESS,
    payload
  })
  dispatch(HIDE_MODAL)
  toastr.success('Thread Status Updated Successfully!')
}

export const updateThreadStatusFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_THREAD_STATUS_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to update Thread Status!')
}
export const createPseudoDesignationSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_PSEUDO_DESIGNATION_SUCCESS,
    payload
  })

  toastr.success('Pseudo Designation Created Successfully!')
}

export const createPseudoDesignationFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_PSEUDO_DESIGNATION_FAILURE,
    payload
  })
  errorsAlert(payload, 'Failed to Create Pseudo Designation!')
}

export const updatePseudoDesignationSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_PSEUDO_DESIGNATION_SUCCESS,
    payload
  })

  toastr.success('Pseudo Designation Updated Successfully!')
}

export const updatePseudoDesignationFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_PSEUDO_DESIGNATION_FAILURE,
    payload
  })
  errorsAlert(payload, 'Failed to update Pseudo Designation!')
}

export const fetchPseudoProfilesSuccess = (payload) => ({
  type: types.FETCH_PSEUDO_PROFILES_SUCCESS,
  payload
})

export const fetchPseudoProfilesFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_PSEUDO_PROFILES_FAILURE,
    payload
  })
  errorsAlert(payload, 'Failed to Fetch All Pseudo Profiles')
}

export const fetchPseudoDesignationsSuccess = (payload) => ({
  type: types.FETCH_PSEUDO_DESIGNATIONS_SUCCESS,
  payload
})

export const fetchPseudoDesignationsFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_PSEUDO_DESIGNATIONS_FAILURE,
    payload
  })
  errorsAlert(payload, 'Failed to Fetch All Pseudo Designations')
}

export const setProjectCreated = (payload) => ({
  type: types.SET_PROJECT_CREATED,
  payload
})

export const loadingProjectList = () => (dispatch) => dispatch({ type: types.LOADING_PROJECT_LIST })

export const fetchUserProjectsSuccess = (payload) => ({
  type: types.FETCH_USER_PROJECTS_SUCCESS,
  payload
})

export const fetchUserProjectsFailure = (payload) => ({
  type: types.FETCH_USER_PROJECTS_FAILURE,
  payload
})

export const fetchUsersProjectsSuccess = (payload) => ({
  type: types.FETCH_USERS_PROJECTS_SUCCESS,
  payload
})

export const fetchUsersProjectsFailure = (payload) => ({
  type: types.FETCH_USERS_PROJECTS_FAILURE,
  payload
})

export const createProjectMemberSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_PROJECT_MEMBER_SUCCESS,
    payload
  })
  dispatch(HIDE_MODAL)

  toastr.success('New Project Member Added Successfully!')
}

export const createProjectMemberFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_PROJECT_MEMBER_FAILURE,
    payload
  })
  dispatch(HIDE_MODAL)

  toastr.error('Failed to Create Poject Member!')
}

export const fetchProjectMembersSuccess = (payload) => ({
  type: types.FETCH_PROJECT_MEMBERS_SUCCESS,
  payload
})

export const fetchProjectMembersFailure = (payload) => ({
  type: types.FETCH_PROJECT_MEMBERS_FAILURE,
  payload
})

export const updateProjectMemberSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_PROJECT_MEMBER_SUCCESS,
    payload
  })
  dispatch(HIDE_MODAL)

  toastr.success('Project Member Updated Successfully!')
}

export const updateProjectMemberFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_PROJECT_MEMBER_FAILURE,
    payload
  })
  dispatch(HIDE_MODAL)
  errorsAlert(payload, 'Failed to Update Poject Member!')
}

export const updateProjectEligibleMembersSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_PROJECT_ELIGIBLE_MEMBERS_SUCCESS,
    payload
  })
}

export const deleteProjectMemberSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.DELETE_PROJECT_MEMBER_SUCCESS,
    payload
  })

  toastr.success('Project Member Deleted Successfully!')
}

export const deleteProjectMemberFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.DELETE_PROJECT_MEMBER_FAILURE,
    payload
  })

  toastr.error('Failed to Delete Poject Member!')
}

export const deleteUserProjectsHistorySuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.DELETE_USER_PROJECTS_HISTORY_SUCCESS,
    payload
  })

  toastr.success('User Project History Deleted Successfully!')
}

export const deleteUserProjectsHistoryFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.DELETE_USER_PROJECTS_HISTORY_FAILURE,
    payload
  })

  toastr.error('Failed to Delete User Project History!')
}

export const clearUserProjectHistory = () => ({
  type: types.CLEAR_USER_PROJECT_HISTORY
})

export const fetchUsersForProjectSuccess = (payload) => ({
  type: types.FETCH_USERS_FOR_PROJECT_SUCCESS,
  payload
})

export const fetchUsersForProjectFailure = (payload) => ({
  type: types.FETCH_USERS_FOR_PROJECT_FAILURE,
  payload
})

export const fetchUserProjectMembersSuccess = (payload) => ({
  type: types.FETCH_USER_PROJECT_MEMBERS_SUCCESS,
  payload
})

export const fetchUserProjectMembersFailure = (payload) => ({
  type: types.FETCH_USER_PROJECT_MEMBERS_FAILURE,
  payload
})

export const clearProjectMembers = () => ({
  type: types.CLEAR_PROJECT_MEMBERS
})

export const fetchUserProjectsHistorySuccess = (payload) => ({
  type: types.FETCH_USER_PROJECTS_HISTORY_SUCCESS,
  payload
})

export const fetchUserProjectsHistoryFailure = (payload) => ({
  type: types.FETCH_USER_PROJECTS_HISTORY_FAILURE,
  payload
})

export const createUserProjectsHistorySuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_USER_PROJECTS_HISTORY_SUCCESS,
    payload
  })
  dispatch(HIDE_MODAL)

  toastr.success('Project History Created Successfully!')
}

export const createUserProjectsHistoryFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_USER_PROJECTS_HISTORY_FAILURE,
    payload
  })
  dispatch(HIDE_MODAL)

  toastr.error('Failed to Create Project History!')
}

export const updateUserProjectsHistorySuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_USER_PROJECTS_HISTORY_SUCCESS,
    payload
  })
  dispatch(HIDE_MODAL)

  toastr.success('User Projects History Updated Successfully!')
}

export const updateUserProjectsHistoryFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_USER_PROJECTS_HISTORY_FAILURE,
    payload
  })
  dispatch(HIDE_MODAL)

  toastr.error('Failed to Update Projects History!')
}

export const fetchUsersForProjectModuleSuccess = (payload) => ({
  type: types.FETCH_USERS_FOR_PROJECT_MODULE_SUCCESS,
  payload
})

export const fetchUsersForProjectModuleFailure = (payload) => ({
  type: types.FETCH_USERS_FOR_PROJECT_MODULE_FAILURE,
  payload
})

export const fetchUserProjectsForClaimsSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_USER_PROJECTS_FOR_CLAIMS_SUCCESS,
    payload
  })
}

export const fetchUserProjectsForClaimsFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_USER_PROJECTS_FOR_CLAIMS_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to Fetch User Projects for Claims')
}

export const fetchCsvDataSuccess = (payload) => ({
  type: types.FETCH_CSV_DATA_SUCCESS,
  payload
})

export const fetchCsvDataFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_CSV_DATA_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to Fetch CSV Data')
}

export const uploadCsvInvoiceSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.UPLOAD_INVOICE_CSV_SUCCESS,
    payload
  })

  toastr.success('CSV Uploaded Successfully')
}

export const uploadCsvInvoiceFailure =
  (payload, ignoreToastr = false) =>
  (dispatch) => {
    dispatch({
      type: types.UPLOAD_INVOICE_CSV_FAILURE,
      payload
    })

    if (!ignoreToastr) toastr.error('Failed to Upload CSV')
  }

export const fetchMonthlyInvoiceDataSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_MONTHLY_INVOICE_DATA_SUCCESS,
    payload
  })
}

export const fetchMonthlyInvoiceDataFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_MONTHLY_INVOICE_DATA_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to fetch Monthly Invoice Data')
}
export const fetchInvoiceCsvAttributeSuccess = (payload) => ({
  type: types.FETCH_INVOICE_CSV_ATTRIBUTES_SUCCESS,
  payload
})

export const fetchInvoiceCsvAttributeFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_INVOICE_CSV_ATTRIBUTES_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to fetch csv attributes')
}

export const fetchProjectCsvAttributesSuccess = (payload) => ({
  type: types.FETCH_PROJECT_CSV_ATTRIBUTES_SUCCESS,
  payload
})

export const fetchProjectCsvAttributesFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_PROJECT_CSV_ATTRIBUTES_FAILURE,
    payload
  })
  errorsAlert(payload, 'Failed to fetch project csv attributes')
}

export const fetchProjectCsvDataSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_PROJECT_CSV_DATA_SUCCESS,
    payload
  })
  toastr.success(payload.message)
}

export const fetchProjectCsvDataFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_PROJECT_CSV_DATA_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to Generate CSV')
}

export const fetchProjectTimelineSuccess = (payload) => ({
  type: types.FETCH_PROJECT_TIMELINE_SUCCESS,
  payload
})

export const fetchProjectTimelineFailure = (_) => {
  toastr.error('Failed to fetch timeline data')
}

export const fetchSelectTimelineProjectsSuccess = (payload) => ({
  type: types.FETCH_SELECT_TIMELINE_PROJECTS_SUCCESS,
  payload
})

export const fetchSelectPseudoProfileSuccess = (payload) => ({
  type: types.FETCH_SELECT_PSEUDO_PROFILE_SUCCESS,
  payload
})

export const fetchSelectPseudoProfileFailure = (_) => {
  toastr.error('Failed to fetch pseudo profile data')
}

export const updateProjectPseudoProfileSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_PROJECT_PSEUDO_PROFILE_SUCCESS,
    payload
  })

  toastr.success('Pseudo Profile successfully updated!')
}

export const updateProjectPseudoProfileFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_PROJECT_PSEUDO_PROFILE_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to update project pseudo profile')
}

export const destroyProjectPseudoProfileStatusSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.DESTROY_PROJECT_PSEUDO_PROFILE_STATUS_SUCCESS,
    payload
  })

  toastr.success('Pseudo Profile Status successfully updated!')
}

export const destroyProjectPseudoProfileStatusFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.DESTROY_PROJECT_PSEUDO_PROFILE_STATUS_FAILURE,
    payload
  })

  toastr.error('Failed to destroy project pseudo profile status')
}

export const fetchCountriesSuccess = (payload) => ({
  type: types.FETCH_COUNTRIES_SUCCESS,
  payload
})

export const fetchCountriesFailure = (payload) => () => {
  errorsAlert(payload, 'Failed to fetch countries!')
}

export const fetchProjectServicesSuccess = (payload) => ({
  type: types.FETCH_PROJECT_SERVICES_SUCCESS,
  payload
})

export const fetchProjectServicesFailure = (payload) => () => {
  errorsAlert(payload, 'Failed to fetch project services!')
}
